import onLoadAsync from '@/shared/on_load_async.js';
import coopenerEmailRegex from '@/shared/coopener_email_regex.js';

onLoadAsync(() => {
  const loginElement = document.querySelector('.js_login_form');
  if (loginElement === null) { return; }

  const emailInput = loginElement.querySelector('.js_login_email_input');
  const passwordInput = loginElement.querySelector('.js_password_input');
  const submitButton = loginElement.querySelector('.js_login_button');

  const updateButton = () => {
    const validEmail = coopenerEmailRegex.test(emailInput.value);
    const nonEmptyPassword = passwordInput.value.length > 0;
    submitButton.disabled = !(validEmail && nonEmptyPassword);
  };

  updateButton();

  emailInput.addEventListener('input', updateButton);
  emailInput.addEventListener('change', updateButton); // This event is triggered when using a password manager's autocompletion
  emailInput.addEventListener('input', updateButton);

  passwordInput.addEventListener('input', updateButton);
  passwordInput.addEventListener('change', updateButton);
});
